<template>
	<div class="header">
		<div class="left">
			<i v-if="hasBack" class="el-icon-back" @click="back"></i>
			<span style="font-size: 15px; color: #00000073">{{ name }}</span>
		</div>
		<div class="right">
			<div class="merchant-select" style="margin-right: 20px" v-if="dataList.length > 0">
				<a-popover placement="bottomRight">
					<template #content>
						<a-row class="box">
							<a-col class="boxText" v-for="(item, index) in dataList" @click="changeMerchant(item)" :key="index" :span="8">
								{{ item.name }}
							</a-col>
						</a-row>
					</template>
					<template #title>
						<span>切换商户</span>
					</template>
					{{ merchantName }}
				</a-popover>
			</div>
			<span class="mr20" v-else>{{ merchantName }}</span>
			<a-popover placement="bottomRight">
				<template #content>
					<p @click="changePassword" class="txtStyle">修改密码</p>
					<p @click="logout" class="txtStyle">退出登录</p>
				</template>
				<template #title>
					<span>{{ (userInfo && userInfo.mobile) || "" }}</span>
				</template>
				<a-avatar size="small">
					<template #icon>
						<UserOutlined />
					</template>
				</a-avatar>
				{{ (userInfo && userInfo.userName) || "" }}
			</a-popover>
		</div>
	</div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue"
import { useRouter } from "vue-router"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { localGet, pathMap } from "@/common/utils"
import { deleteCookie } from "@/common/utils/cookie"
import { useStore } from "vuex"
export default {
	name: "Header",
	setup() {
		const router = useRouter()
		const store = useStore()
		const state = reactive({
			name: "idaas",
			userInfo: store.getters["user/user"],
			hasBack: false,
			dataList: localGet("merchantList") || [],
			merchantName: store.getters["user/merchantName"],
		})
		onMounted(() => {
			state.name = pathMap[location.pathname.split("/")[1]]
		})
		const logout = () => {
			axios.post(api.loginOut).then(() => {
				localStorage.clear()
				deleteCookie(() => {
					router.push("login")
				})
			})
		}
		const back = () => {
			router.back()
		}
		router.beforeEach((to) => {
			const { id } = to.query
			state.name = pathMap[to.name]
			if (id && to.name == "add") {
				state.name = "编辑商品"
			}
			state.hasBack = ["level2", "level3", "order_detail"].includes(to.name)
		})
		const changePassword = () => {
			router.push("setting")
		}
		const changeMerchant = (item) => {
			axios
				.post(api.switchMerchant, {
					merchantCode: item.code,
				})
				.then((res) => {
					store.dispatch("user/LOGIN", res)
					history.go(0)
				})
				.catch((err) => {
					console.log(err)
				})
		}
		return {
			...toRefs(state),
			changePassword,
			logout,
			back,
			changeMerchant,
		}
	},
}
</script>

<style scoped>
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
}
.el-icon-back {
	border: 1px solid #e9e9e9;
	padding: 4px;
	border-radius: 50px;
	margin-right: 10px;
}
.right {
	display: flex;
}
.right > div > .icon {
	font-size: 18px;
	margin-right: 6px;
}
.author {
	margin-left: 10px;
	cursor: pointer;
}
</style>
<style lang="scss" scoped>
.popper-user-box {
	background-color: rgb(201, 142, 34);
	.popper-user-box .nickname {
		position: relative;
	}
}

.popper-user-box .nickname .logout {
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
}
.txtStyle {
	text-align: center;
	border: 1px solid gainsboro;
	line-height: 25px;
	cursor: pointer;
}
.boxText {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
}
.boxText:hover {
	color: #43a0f7;
}
</style>
